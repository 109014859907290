import "~/util/setup-nonce";
import "es6-promise/auto"; // the Promise polyfill needs to be included unconditionally
import { polyfilled } from "~/util/polyfills";
import { createApp } from "~/util/setup-vue";
import App from "~/Frontend.vue";
import { getRouter } from "~/router";
import { getRoutes } from "~/router/frontend-routes";
import { apolloProvider } from "~/graphql/graphql";
import { init as authInit, ReactiveAuth, setAuthSuffix } from "~/util/auth";
import KeyboardAware from "~/components/mixins/KeyboardAware";
import RootMixin from "~/components/mixins/RootMixin";

setAuthSuffix("subscriber");
ReactiveAuth.setRequiredRole({ role: "subscriber" });
authInit();

const app = createApp({
  mixins: [KeyboardAware, RootMixin],
  router: getRouter(getRoutes()),
  apolloProvider,
  render: (h) => h(App),
});

document.addEventListener("DOMContentLoaded", () => {
  polyfilled(function () {
    app.$mount("#app");
  });
});
