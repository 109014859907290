import gql from "graphql-tag";
import {
  ChildFragment,
  ErrorFragment,
  EvidenceFragment,
  SubscriptionFragment,
} from "~/graphql/fragments";

export const SEND_RESET_PASSWORD_INSTRUCTIONS_MUTATION = gql`
  mutation sendResetPasswordInstructions($email: String!) {
    sendResetPasswordInstructions(email: $email)
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($password: String!, $resetPasswordToken: String!) {
    resetPassword(
      password: $password
      passwordConfirmation: $password
      resetPasswordToken: $resetPasswordToken
    )
  }
`;

export const UPDATE_PROFILE_MUTATION = gql`
  mutation updateProfile($attributes: UpdateProfileInput!) {
    updateProfile(attributes: $attributes) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const CHANGE_EMAIL_MUTATION = gql`
  mutation changeEmail($currentPassword: String!, $newEmail: String!) {
    changeEmail(currentPassword: $currentPassword, newEmail: $newEmail) {
      ...ErrorFragment
    }
  }
  ${ErrorFragment}
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      ...ErrorFragment
    }
  }
  ${ErrorFragment}
`;

export const RESEND_CONFIRMATION_INSTRUCTIONS_MUTATION = gql`
  mutation resendConfirmationInstructions($email: String!) {
    resendConfirmationInstructions(email: $email)
  }
`;

export const CONFIRM_ACCOUNT_MUTATION = gql`
  mutation confirmAccount($confirmationToken: String!) {
    confirmAccount(confirmationToken: $confirmationToken) {
      ...ErrorFragment
    }
  }
  ${ErrorFragment}
`;

export const SIGN_UP_MUTATION = gql`
  mutation signUp($attributes: UserInput!) {
    signUp(attributes: $attributes) {
      node {
        id
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const RESEND_UNLOCK_INSTRUCTIONS_MUTATION = gql`
  mutation resendUnlockInstructions($email: String!) {
    resendUnlockInstructions(email: $email)
  }
`;

export const UNLOCK_MUTATION = gql`
  mutation unlock($unlockToken: String!) {
    unlock(unlockToken: $unlockToken) {
      ...ErrorFragment
    }
  }
  ${ErrorFragment}
`;

export const DESTROY_CHILD = gql`
  mutation($id: ID!) {
    destroyChild(input: { id: $id })
  }
`;

export const UPDATE_CHILD = gql`
  mutation(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $birthday: ISO8601Date!
  ) {
    updateChild(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        birthday: $birthday
      }
    ) {
      errors {
        ...ErrorFragment
      }
      node {
        ...ChildFragment
      }
    }
  }
  ${ErrorFragment}
  ${ChildFragment}
`;

export const UPDATE_MEASLES_EVIDENCE = gql`
  mutation($id: ID!, $uploads: [UploadInput!]!) {
    updateMeaslesEvidence(input: { id: $id, uploads: $uploads }) {
      errors {
        ...ErrorFragment
      }
      node {
        ...EvidenceFragment
      }
    }
  }
  ${ErrorFragment}
  ${EvidenceFragment}
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation(
    $childId: ID!
    $serviceId: ID!
    $startsOn: ISO8601Date!
    $endsOn: ISO8601Date
    $emergencyContacts: [EmergencyContactInput!]!
    $notes: String
    $selectedWeekdays: [Weekday!]!
    $termsAccepted: Boolean!
  ) {
    payload: createSubscription(
      input: {
        childId: $childId
        serviceId: $serviceId
        startsOn: $startsOn
        endsOn: $endsOn
        emergencyContacts: $emergencyContacts
        notes: $notes
        selectedWeekdays: $selectedWeekdays
        termsAccepted: $termsAccepted
      }
    ) {
      errors {
        ...ErrorFragment
      }
      node {
        ...SubscriptionFragment
      }
    }
  }
  ${ErrorFragment}
  ${SubscriptionFragment}
`;
