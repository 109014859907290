import Vue from "vue";

const requiresAuth = {
  requiresAuth: true,
};
const requiresNoAuth = {
  requiresNoAuth: true,
};

const SubView = Vue.extend({
  name: "SubView",
  render(h) {
    return h("RouterView");
  },
});

export function getRoutes() {
  return [
    // basic routes
    // ===================================================================================================
    {
      path: "/",
      name: "index",
      component: require("~/pages/frontend/index.vue").default,
    },
    {
      path: "/login",
      name: "login",
      component: require("~/pages/user/login.vue").default,
      meta: requiresNoAuth,
    },
    {
      path: "/register",
      name: "register",
      component: require("~/pages/user/register.vue").default,
      meta: requiresNoAuth,
    },
    {
      path: "/verify-email",
      name: "verify-email",
      component: require("~/pages/user/verify-email.vue").default,
    },
    {
      path: "/impressum",
      name: "imprint",
      component: require("~/pages/imprint").default,
    },
    {
      path: "/datenschutz",
      name: "privacy",
      component: require("~/pages/privacy").default,
    },
    {
      path: "/kontakt",
      name: "contact",
      component: require("~/pages/contact").default,
    },

    // logged in
    // ===================================================================================================
    {
      path: "/me",
      name: "profile",
      component: () =>
        import(/* webpackChunkName: "user" */ "~/pages/user/profile.vue"),
      meta: requiresAuth,
    },
    {
      path: "/bank-accounts",
      name: "bank-accounts",
      component: () =>
        import(
          /* webpackChunkName: "f-signed-in" */ "~/pages/frontend/bank-accounts/list.vue"
        ),
      meta: requiresAuth,
    },
    {
      path: "/bank-accounts/new",
      name: "new-bank-account",
      component: () =>
        import(
          /* webpackChunkName: "f-signed-in" */ "~/pages/frontend/bank-accounts/new-bank-account.vue"
        ),
      props: (route) => ({
        missing: route.query.missing === "1",
      }),
      meta: requiresAuth,
    },
    {
      path: "/children",
      name: "children",
      component: () =>
        import(
          /* webpackChunkName: "f-signed-in" */ "~/pages/frontend/children/index.vue"
        ),
      meta: requiresAuth,
    },
    {
      path: "/children/new-child",
      name: "new-child",
      component: () =>
        import(
          /* webpackChunkName: "f-signed-in" */ "~/pages/frontend/children/new-child.vue"
        ),
      props: (route) => ({
        missing: route.query.missing === "1",
      }),
      meta: requiresAuth,
    },
    {
      path: "/children/:childId",
      component: SubView,
      children: [
        {
          path: "",
          redirect: { name: "children" },
        },
        {
          path: "edit",
          name: "edit-child",
          component: () =>
            import(
              /* webpackChunkName: "f-signed-in" */ "~/pages/frontend/children/edit-child.vue"
            ),
          props: (route) => ({
            childId: route.params.childId,
          }),
        },
        {
          path: "measles-protection-evidence",
          name: "measles-protection-evidence",
          component: () =>
            import(
              /* webpackChunkName: "f-signed-in" */ "~/pages/frontend/children/measles-protection-evidence.vue"
            ),
          props: (route) => ({
            childId: route.params.childId,
          }),
        },
        {
          path: "subscription/new",
          name: "new-subscription",
          component: () =>
            import(
              /* webpackChunkName: "f-signed-in" */ "~/pages/frontend/children/new-subscription.vue"
            ),
          props: (route) => ({
            childId: route.params.childId,
          }),
        },
        {
          path: "subscription/:subscriptionId",
          redirect: { name: "children" },
        },
        {
          path: "subscription/:subscriptionId/emergency-contacts",
          name: "edit-subscription-emergency-contacts",
          component: () =>
            import(
              /* webpackChunkName: "f-signed-in" */ "~/pages/frontend/children/edit-subscription-emergency-contacts.vue"
            ),
          props: (route) => ({
            childId: route.params.childId,
            subscriptionId: route.params.subscriptionId,
          }),
        },
        {
          path: "subscription/:subscriptionId/configuration",
          name: "edit-subscription-configuration",
          component: () =>
            import(
              /* webpackChunkName: "f-signed-in" */ "~/pages/frontend/children/edit-subscription-configuration.vue"
            ),
          props: (route) => ({
            childId: route.params.childId,
            subscriptionId: route.params.subscriptionId,
          }),
        },
        {
          path: "subscription/:subscriptionId/cancel",
          name: "cancel-subscription",
          component: () =>
            import(
              /* webpackChunkName: "f-signed-in" */ "~/pages/frontend/children/cancel-subscription.vue"
            ),
          props: (route) => ({
            childId: route.params.childId,
            subscriptionId: route.params.subscriptionId,
          }),
        },
        {
          path: "subscription/:subscriptionId/uncancel",
          name: "uncancel-subscription",
          component: () =>
            import(
              /* webpackChunkName: "f-signed-in" */ "~/pages/frontend/children/uncancel-subscription.vue"
            ),
          props: (route) => ({
            childId: route.params.childId,
            subscriptionId: route.params.subscriptionId,
          }),
        },
        {
          path: "subscription/:subscriptionId/extend",
          name: "extend-subscription",
          component: () =>
            import(
              /* webpackChunkName: "f-signed-in" */ "~/pages/frontend/children/extend-subscription.vue"
            ),
          props: (route) => ({
            childId: route.params.childId,
            subscriptionId: route.params.subscriptionId,
          }),
        },
      ],
      meta: requiresAuth,
    },

    // seldom used user auth routes
    // ===================================================================================================
    {
      path: "/password-reset",
      name: "password-reset",
      component: () =>
        import(
          /* webpackChunkName: "f-auth-routes" */ "~/pages/user/password-reset.vue"
        ),
    },
    {
      path: "/set-password",
      name: "set-password",
      component: () =>
        import(
          /* webpackChunkName: "f-auth-routes" */ "~/pages/user/set-password.vue"
        ),
    },
    {
      path: "/validate-email",
      name: "validate-email",
      component: () =>
        import(
          /* webpackChunkName: "f-auth-routes" */ "~/pages/user/validate-email.vue"
        ),
    },
    {
      path: "/unlock-email",
      name: "unlock-email",
      component: () =>
        import(
          /* webpackChunkName: "f-auth-routes" */ "~/pages/user/unlock-email.vue"
        ),
      meta: requiresNoAuth,
    },
    {
      path: "/unlock",
      name: "unlock",
      component: () =>
        import(
          /* webpackChunkName: "f-auth-routes" */ "~/pages/user/unlock.vue"
        ),
    },
    {
      path: "/users/invitation/accept",
      name: "accept-invitation",
      component: () =>
        import(
          /* webpackChunkName: "f-auth-routes" */ "~/pages/user/accept-invitation.vue"
        ),
    },

    // fallback 404 route
    // ===================================================================================================
    { path: "*", name: "404", component: require("~/pages/404.vue").default },
  ];
}
