<template>
  <el-main v-loading.fullscreen.lock="loading" class="Content Content_centered">
    <app-panel :class="$style.panel" main>
      <h1 slot="title" class="AppTitle">
        Registrieren
      </h1>
      <el-form
        ref="form"
        :model="model"
        :rules="rules"
        label-position="top"
        label-suffix=": "
        @submit.native.prevent
      >
        <el-form-item label="Anrede" prop="salutation">
          <el-select
            v-model="model.salutation"
            :class="$style.salutationSelect"
            placeholder="Wie sollen wir Sie anreden?"
          >
            <el-option
              v-for="v in possibleSalutations"
              :key="v"
              :label="v"
              :value="v"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Ihr Name (Vor- und Nachname)" prop="name">
          <el-input
            v-model="model.name"
            auto-complete="name"
            placeholder="Karla Karlsruhe oder Gräfin Martha von Baden"
          />
        </el-form-item>
        <el-form-item
          :error="serverErrors.email"
          label="Ihre E-Mail-Adresse"
          prop="email"
        >
          <el-input
            v-model="model.email"
            auto-complete="email"
            placeholder="karla@karlsruhe.de"
          />
        </el-form-item>
        <el-form-item
          :error="serverErrors.password"
          label="Passwort"
          prop="password"
        >
          <el-input
            v-model="model.password"
            auto-complete="new-password"
            placeholder="Mindestens 6 Zeichen und nicht zu leicht"
            type="password"
          />
        </el-form-item>
        <el-form-item
          label="Passwort (wiederholen)"
          prop="passwordConfirmation"
        >
          <el-input
            v-model="model.passwordConfirmation"
            auto-complete="new-password"
            type="password"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit().catch(() => {})">
            Registrieren
          </el-button>
          <auth-meta-navi />
        </el-form-item>
      </el-form>
    </app-panel>
  </el-main>
</template>

<script>
import AppPanel from "../../components/common/layout/AppPanel";
import AuthMetaNavi from "../../components/common/layout/AuthMetaNavi";
import { possibleSalutations, possibleSchools } from "../../util/user";
import {
  ensureValidForm,
  getPasswordPolicyValidator,
  parseServerErrorsAndShowMessage,
  showError,
} from "../../util/validation";
import { SIGN_UP_MUTATION } from "~/graphql/mutations";

function getEmpty() {
  return {
    salutation: "",
    name: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  };
}

export default {
  components: { AuthMetaNavi, AppPanel },
  auth: "guest",
  data() {
    return {
      loading: 0,
      model: getEmpty(),
      serverErrors: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    possibleSalutations() {
      return possibleSalutations();
    },
    possibleSchools() {
      return possibleSchools();
    },
    rules() {
      const validatePasswordPolicy = getPasswordPolicyValidator(this.$apollo);
      const validatePasswordConfirm = (rule, value, callback) => {
        if (value && value !== this.model.password) {
          callback(new Error("Stimmt nicht mit Passwort überein"));
        } else {
          callback();
        }
      };
      const validateSalutations = (rule, value, callback) => {
        if (value && !this.possibleSalutations.includes(value)) {
          callback(new Error("Keine Anrede die wir kennen"));
        } else {
          callback();
        }
      };
      return {
        salutation: [
          {
            required: true,
            type: "string",
            message: "Muss ausgefüllt werden",
            trigger: "change",
          },
          {
            validator: validateSalutations,
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            type: "string",
            message: "Muss ausgefüllt werden",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            type: "email",
            message: "Muss eine valide E-Mail-Adresse sein",
            trigger: "blur",
          },
          // {
          //     validator: validateUniqueEmail,
          //     trigger: 'blur',
          // },
        ],
        password: [
          {
            required: true,
            type: "string",
            message: "Muss ausgefüllt werden",
            trigger: "blur",
          },
          {
            validator: validatePasswordPolicy,
            trigger: "blur",
          },
        ],
        passwordConfirmation: [
          {
            required: true,
            type: "string",
            message: "Muss ausgefüllt werden",
            trigger: "blur",
          },
          { validator: validatePasswordConfirm, trigger: "blur" },
        ],
      };
    },
  },
  methods: {
    submit() {
      return ensureValidForm(this.$refs.form, () => {
        this.loading += 1;
        return this.$apollo
          .mutate({
            mutation: SIGN_UP_MUTATION,
            variables: {
              attributes: this.model,
            },
          })
          .finally(() => {
            this.loading -= 1;
          })
          .then(({ data: { signUp } }) => {
            // Result
            if (signUp.node) {
              return this.$msgbox({
                type: "success",
                title: "Registrierung",
                message:
                  "Wir haben Ihnen eine E-Mail geschickt – um Ihre Registrierung abzuschließen, müssen Sie auf den Link in der E-Mail klicken. Bitte schauen Sie auch in Ihrem Spam-Ordner, falls Sie die E-Mail nicht finden sollten.",
              })
                .catch(() => false)
                .finally(() => {
                  this.$router.push({ name: "login" });
                });
            } else {
              this.serverErrors = parseServerErrorsAndShowMessage(
                signUp.errors,
                Object.keys(this.serverErrors)
              );
            }
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
            return showError(error);
          });
      });
    },
  },
  head() {
    return {
      title: "Registrieren",
    };
  },
};
</script>

<style lang="module.scss" module>
.panel {
  width: 540px;
  max-width: calc(100vw - 40px);
}

.salutationSelect {
  width: 100%;
}
</style>
