<template>
  <el-footer :class="[$style.div, absolute ? $style.absolute : null]">
    <span :class="$style.spacer" />
    <router-link :to="{ name: 'contact' }" class="el-button el-button--text">
      Kontakt
    </router-link>
    <router-link :to="{ name: 'imprint' }" class="el-button el-button--text">
      Impressum
    </router-link>
    <router-link :to="{ name: 'privacy' }" class="el-button el-button--text">
      Datenschutz
    </router-link>
  </el-footer>
</template>

<script>
export default {
  name: "AppFooter",
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="module.css" module>
.div {
  display: flex;
  align-items: center;
}

.div :global(.el-button) {
  margin-left: 10px;
}
.absolute {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.spacer {
  flex: 1;
}
</style>
