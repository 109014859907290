<template>
  <el-container
    v-loading.fullscreen.lock="$auth.loggingIn"
    class="Layout"
    direction="vertical"
  >
    <app-header :unread="badges.unread.totalCount" />
    <transition mode="out-in" name="trans-fade">
      <router-view />
    </transition>
    <app-footer />
    <img
      alt=""
      class="Layout__footer-visual u-do-not-print"
      src="~assets/unten.svg"
    />
  </el-container>
</template>

<script>
import AppHeader from "./components/common/layout/AppHeader";
import AppFooter from "./components/common/layout/AppFooter";
import gql from "graphql-tag";

export default {
  name: "FrontendLayout",
  components: { AppFooter, AppHeader },
  data() {
    return {
      badges: {
        unread: {
          totalCount: null,
        },
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$auth.loggedIn;
    },
    authError() {
      return this.$auth.error;
    },
  },
  apollo: {
    badges: {
      throttle: 500,
      pollInterval: 90000,
      fetchPolicy: "no-cache",
      update: (data) => data.me,
      skip: (vm) => !vm.loggedIn,
      query: gql`
        query {
          me {
            unread: messages(read: false) {
              totalCount
            }
          }
        }
      `,
    },
  },
  mounted() {
    if (this.authError) {
      console.log("authError", this.authError);
      return this.$msgbox({
        title: "Fehler",
        message:
          "Es ist ein Fehler beim Authentifizieren aufgetreten – sie wurden ausgeloggt" +
          this.authError,
      })
        .catch(() => false)
        .finally(() => {
          return this.$router.replace({ name: "login" });
        });
    }
  },
  methods: {
    refreshBadges() {
      this.$apollo.queries.badges.refetch();
    },
  },
  head: {
    titleTemplate: "%s - stja@school",
    description: `Der stja bietet in einigen Schulen Karlsruhes Nachmittagsbetreuung und/oder Mittagessen an. Mit dieser Website können Eltern diese Leistungen direkt online bestellen und verwalten.`,
  },
};
</script>

<style></style>
