<template>
  <el-main
    v-if="!$auth.loggedIn"
    :class="$style.container"
    class="Content Content_centered"
  >
    <div>
      <h1>
        <logo :class="$style.logo"
:triangle-color="triangleColor" />
      </h1>
      <div :class="$style.whatIsThis">
        <h2 :class="$style.subline">
          Das Anmeldeportal des stja für Klever und die Ganztagsgrundschulen
        </h2>
        <p>
          Der Stadtjugendausschuss e.V. Karlsruhe bietet in einigen Schulen in
          Karlsruhe Nachmittagsbetreuung&nbsp;und/oder&nbsp;Mittagessen an.
        </p>
        <p>
          Bis jetzt mussten Sie Ihr Kind noch offline für die Angebote anmelden.
          Ab dem Schuljahr 2021/22 ermöglichen wir Ihnen für folgende Standorte
          Ihr Kind online anzumelden und Änderungen an Ihrem Vertrag selbst
          vorzunehmen:<br >
          <span v-for="school in schools"
:key="school.id">
            • {{ school.name }}<br >
          </span>
        </p>
      </div>
      <div :class="$style.links">
        <el-button type="primary"
@click="goTo('register')">
          Registrieren
        </el-button>
        <el-button plain
type="primary" @click="goTo('login')">
          Login
        </el-button>
      </div>
    </div>
  </el-main>
  <el-main v-else
class="Content">
    <div :class="$style.stepsWrap">
      <el-steps align-center>
        <el-step :status="stepStatusBankAccount"
title="SEPA-Mandat">
          <div v-if="!hasBankAccounts"
slot="description">
            <el-button type="primary"
@click="goToBankAccount">
              Erteilen
            </el-button>
          </div>
        </el-step>
        <el-step :status="stepStatusChild"
title="Kind">
          <div v-if="hasBankAccounts && !hasChildren"
slot="description">
            <el-button type="primary"
@click="goToNewChild">
              Anlegen
            </el-button>
          </div>
        </el-step>
        <el-step :status="stepStatusSubscription"
title="Abo">
          <div v-if="hasChildren && !hasSubscriptions"
slot="description">
            <el-button type="primary"
@click="goToChildren">
              Abschließen
            </el-button>
          </div>
        </el-step>
      </el-steps>
    </div>
    <div :class="$style.messages">
      <h1>Meine Nachrichten</h1>
      <message-list
        :go-to-next-page="nextMessagesPage"
        :go-to-previous-page="previousMessagesPage"
        :has-next-page="messages.pageInfo.hasNextPage"
        :has-previous-page="messages.pageInfo.hasPreviousPage"
        :list="messages.nodes"
        :loading="$apollo.queries.messages.loading"
        :on-message-expanded="onMessageExpanded"
        :reload="
          () => {
            $apollo.queries.messages.refetch();
          }
        "
      >
        <el-form slot="filter"
inline>
          <el-form-item label="Status">
            <el-radio-group v-model="messagesFilter.read">
              <el-radio
:disabled="false" :label="null"> alle </el-radio>
              <el-radio :disabled="false"
:label="false">
                nur ungelesen
              </el-radio>
              <!--              <el-radio :disabled="false" :label="true">-->
              <!--                nur gelesen-->
              <!--              </el-radio>-->
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="messagesFilter.type"
              clearable
              placeholder="Worum es geht"
            >
              <el-option label="Abos"
value="subscriptions" />
              <el-option label="Masernschutz"
value="evidences" />
              <el-option label="SEPA-Mandate"
value="bankAccounts" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="messagesFilter.search"
              :class="$style.searchInput"
              clearable
              placeholder="Volltextsuche in den Nachrichten"
              prefix-icon="el-icon-search"
            />
          </el-form-item>
        </el-form>
        <template v-if="messagesFilter.read === false"
slot="empty">
          <i class="el-icon-bell" /> Keine ungelesenen Nachrichten
          <el-button type="text"
@click="messagesFilter.read = null">
            alle anzeigen
          </el-button>
        </template>
      </message-list>
    </div>
  </el-main>
</template>

<script>
import Logo from "~/components/common/layout/Logo.vue";
import { STJA_COLORS } from "~/util/color";
import gql from "graphql-tag";
import MessageList from "~/components/messages/MessageList";
import {
  deserializeBool,
  getEmptyConnection,
  getEmptyPaging,
  getPagingVariables,
  serializeBool,
} from "~/util/paging";
import { MessageFragment, PageInfoFragment } from "~/graphql/fragments";
import {
  bankAccountsTypes,
  evidencesTypes,
  subscriptionsTypes,
} from "~/util/messages";

function getRandomColor() {
  const possibleColors = [
    "tuerkis",
    "signalgruen",
    "gelbgruen",
    "maingruen",
    "gelb",
    "orange",
    "signalrot",
    "kirschrot",
    "purpur",
    "flieder",
    "dunkelblau",
    "mittelblau",
  ];

  return STJA_COLORS[
    possibleColors[Math.floor(Math.random() * possibleColors.length)]
  ];
}

export default {
  components: {
    MessageList,
    Logo,
  },
  data() {
    return {
      triangleColor: getRandomColor(),
      messages: getEmptyConnection(),
      messagesPaging: getEmptyPaging(),
      messagesFilter: {
        read: deserializeBool(this.$route.query.read),
        search: null,
        type: null,
      },
      me: {
        bankAccounts: {
          exists: null,
        },
        children: {
          exists: null,
        },
        subscriptions: {
          exists: null,
        },
      },
      schools: [],
    };
  },
  computed: {
    subscriptionsTypes() {
      return subscriptionsTypes();
    },
    evidencesTypes() {
      return evidencesTypes();
    },
    bankAccountsTypes() {
      return bankAccountsTypes();
    },
    hasBankAccounts() {
      const { bankAccounts } = this.me;
      return bankAccounts.exists;
    },
    hasChildren() {
      const { children } = this.me;
      return children.exists;
    },
    hasSubscriptions() {
      const { subscriptions } = this.me;
      return subscriptions.exists;
    },
    stepStatusBankAccount() {
      const { hasBankAccounts } = this;
      if (hasBankAccounts) {
        return "success";
      }
      return "process";
    },
    stepStatusChild() {
      const { hasBankAccounts, hasChildren } = this;
      if (hasChildren) {
        return "success";
      }
      if (!hasBankAccounts) {
        return "wait";
      }
      return "process";
    },
    stepStatusSubscription() {
      const { hasChildren, hasSubscriptions } = this;
      if (hasSubscriptions) {
        return "success";
      }
      if (!hasChildren) {
        return "wait";
      }
      return "process";
    },
  },
  watch: {
    messagesFilter: {
      deep: true,
      handler(to) {
        this.messagesPaging = getEmptyPaging();
        if (serializeBool(to.read) !== this.$route.query.read) {
          this.$router.replace({
            name: this.$route.name,
            query: {
              read: serializeBool(to.read),
            },
          });
        }
      },
    },
  },
  apollo: {
    messages: {
      context: {
        needsAuth: true,
      },
      throttle: 500,
      loadingKey: "loading",
      fetchPolicy: "no-cache",
      update: (data) => data.me.messages,
      skip: (vm) => !vm.$root.loggedIn,
      query: gql`
        query(
          $first: Int
          $last: Int
          $after: String
          $before: String
          $read: Boolean
          $search: String
          $types: [MessageMessageType!]
        ) {
          me {
            messages(
              first: $first
              last: $last
              after: $after
              before: $before
              read: $read
              search: $search
              types: $types
            ) {
              nodes {
                ...MessageFragment
              }
              pageInfo {
                ...PageInfoFragment
              }
              totalCount
            }
          }
        }
        ${MessageFragment}
        ${PageInfoFragment}
      `,
      variables() {
        const { messagesPaging, messagesFilter } = this;
        const types =
          messagesFilter.type && this[messagesFilter.type + "Types"]
            ? this[messagesFilter.type + "Types"]
            : null;
        return {
          ...getPagingVariables(messagesPaging),
          read: messagesFilter.read,
          search: messagesFilter.search,
          types: types,
        };
      },
    },
    me: {
      skip: (vm) => !vm.$root.loggedIn,
      query: gql`
        query {
          me {
            bankAccounts {
              exists
            }
            children {
              exists
            }
            subscriptions {
              exists
            }
          }
        }
      `,
    },
    schools: {
      throttle: 500,
      loadingKey: "loading",
      update: (data) => data.schools.nodes,
      query: gql`
        query {
          schools(hasServices: true) {
            nodes {
              id
              name
            }
          }
        }
      `,
    },
  },
  mounted() {
    this._setTriangleColorInterval = setInterval(() => {
      this.triangleColor = getRandomColor();
    }, 10000);

    this.$apollo.queries.messages.refetch();
    this.$apollo.queries.me.refetch();
  },
  beforeDestroy() {
    clearInterval(this._setTriangleColorInterval);
  },
  methods: {
    register() {
      return this.$router.push({ name: "register" });
    },
    goTo(name) {
      return this.$router.push({ name });
    },
    nextMessagesPage() {
      this.messagesPaging.direction = ">";
      this.messagesPaging.cursor = this.messages.pageInfo.endCursor;
    },
    previousMessagesPage() {
      this.messagesPaging.direction = "<";
      this.messagesPaging.cursor = this.messages.pageInfo.startCursor;
    },
    onMessageExpanded(message) {
      if (!message.read) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation($id: ID!) {
                markMessageAsRead(input: { id: $id })
              }
            `,
            variables: {
              id: message.id,
            },
          })
          .then(() => {
            message.read = true;
            this.$root.refreshBadges();
          })
          .catch(this.$apolloErrorHandle);
      }
    },
    goToBankAccount() {
      this.$router.push({ name: "new-bank-account", query: { missing: "1" } });
    },
    goToNewChild() {
      this.$router.push({ name: "new-child", query: { missing: "1" } });
    },
    goToChildren() {
      this.$router.push({ name: "children" });
    },
  },
  head: {
    title: "stja@school",
    titleTemplate: null,
  },
};
</script>

<style lang="module.scss" module>
.container {
  text-align: center;
}

.logo {
  margin-top: 30px;
  width: 50vw;
  min-width: 250px;
  max-width: 400px;
  height: auto;
}

.whatIsThis.whatIsThis {
  padding: 15px 10px 0;
  max-width: 600px;
}

.whatIsThis.whatIsThis > * {
  margin-top: 1em;
}

.whatIsThis.whatIsThis *:first-child {
  margin-top: 0;
}

.subline.subline.subline {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.links {
  padding: 15px 10px 0;
  margin-bottom: 30px;
}

.messages {
  margin-bottom: 1rem;
}

.searchInput :global(.el-input__inner) {
  min-width: 300px;
}

.stepsWrap {
  padding: 40px 20px;
  max-width: 800px;
  margin: auto;
}
</style>
