<template>
  <el-header class="AppHeader">
    <router-link
      :style="{ opacity: $route.name === 'index' && notLoggedIn ? 0 : 1 }"
      :to="{ name: 'index' }"
      class="AppHeader__logo"
      aria-label="Zur Startseite"
    >
      <logo :height="42" :triangle-color="null">
        <text
          v-if="envNotProduction"
          x="40"
          y="40"
          stroke="#c60072"
          font-size="40"
        >
          {{ env }}
        </text>
      </logo>
    </router-link>
    <span class="AppHeader__fill" />
    <el-button
      v-if="$root.isMobile"
      class="AppHeader__hamburger-button"
      type="text"
      @click="mobileMenuVisible = !mobileMenuVisible"
    >
      <svg
        class="AppHeader__hamburger"
        height="32px"
        viewBox="0 0 32 32"
        width="32px"
      >
        <path
          d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2 s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2 S29.104,22,28,22z"
        />
      </svg>
    </el-button>

    <el-menu
      v-else
      ref="menu"
      :default-active="$route.name"
      background-color="transparent"
      class="Menu u-do-not-print"
      mode="horizontal"
    >
      <menu-link :to="{ name: 'index' }" only-exact>
        <el-badge :hidden="!unread" :value="unread" type="primary">
          <i class="el-icon-s-home" />
        </el-badge>
      </menu-link>

      <menu-link v-if="loggedIn" :to="{ name: 'children' }">
        <i class="el-icon-s-ticket" />
        Kinder &amp; Abos
      </menu-link>

      <menu-link v-if="loggedIn" :to="{ name: 'bank-accounts' }">
        <i class="el-icon-bank-card" />
        SEPA-Mandat
      </menu-link>

      <el-submenu
        v-show="loggedIn"
        index="user-menu"
        popper-class="AppHeader__submenu"
      >
        <template #title>
          <i class="el-icon-user-solid" />
        </template>
        <menu-link
          :to="{ name: 'profile' }"
          @navigated="$refs.menu.closeMenu('user-menu')"
        >
          <i class="el-icon-setting" />
          Mein Profil
        </menu-link>
        <el-menu-item class="has-link" index="logout">
          <button type="button" @click.prevent.stop="logout">
            <i class="el-icon-switch-button" />
            Logout
          </button>
        </el-menu-item>
      </el-submenu>

      <el-menu-item class="has-link" index="register" v-show="notLoggedIn">
        <router-link :to="{ name: 'register' }" @click.native.stop>
          Registrieren
        </router-link>
      </el-menu-item>
      <el-menu-item class="has-link" index="login" v-show="notLoggedIn">
        <router-link :to="{ name: 'login' }" @click.native.stop>
          Login
        </router-link>
      </el-menu-item>
    </el-menu>
    <el-drawer
      :visible.sync="mobileMenuVisible"
      direction="rtl"
      size="300px"
      title="stja@school"
    >
      <router-link slot="title" :to="{ name: 'index' }">
        <logo :height="42" :triangle-color="null" />
      </router-link>
      <el-menu
        :default-active="$route.name"
        background-color="transparent"
        class="MobileMenu"
        mode="vertical"
      >
        <router-link
          v-show="loggedIn"
          v-slot="{ href, route, navigate, isActive }"
          :to="{ name: 'children' }"
        >
          <el-menu-item
            class="has-link"
            :class="isActive ? 'is-active' : null"
            :index="route.name"
          >
            <a :href="href" @click.stop="navigate">Kinder &amp; Abos</a>
          </el-menu-item>
        </router-link>
        <el-menu-item class="has-link" index="bank-accounts" v-show="loggedIn">
          <router-link :to="{ name: 'bank-accounts' }" @click.native.stop>
            Mein SEPA-Mandat
          </router-link>
        </el-menu-item>
        <el-menu-item class="has-link" index="me" v-show="loggedIn">
          <router-link :to="{ name: 'profile' }" @click.native.stop>
            Mein Profil
          </router-link>
        </el-menu-item>
        <el-menu-item class="has-link" index="logout" v-show="loggedIn">
          <button type="button" @click.prevent.stop="logout">
            Logout
          </button>
        </el-menu-item>
        <el-menu-item class="has-link" index="register" v-show="notLoggedIn">
          <router-link :to="{ name: 'register' }" @click.native.stop>
            Registrieren
          </router-link>
        </el-menu-item>
        <el-menu-item class="has-link" index="login" v-show="notLoggedIn">
          <router-link :to="{ name: 'login' }" @click.native.stop>
            Login
          </router-link>
        </el-menu-item>
      </el-menu>
    </el-drawer>
  </el-header>
</template>

<script>
import Logo from "./Logo";
import MenuLink from "~/components/common/layout/MenuLink";
import { RAILS_ENV } from "~/util/rails.js.erb";

export default {
  name: "AppHeader",
  components: { MenuLink, Logo },
  props: {
    unread: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mobileMenuVisible: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$auth.loggedIn;
    },
    notLoggedIn() {
      return !this.$auth.loggedIn;
    },
    env() {
      return RAILS_ENV;
    },
    envNotProduction() {
      const { env } = this;
      return env !== "production";
    },
  },
  watch: {
    showMobileMenuToggle() {
      this.mobileMenuVisible = false;
    },
    $route() {
      this.mobileMenuVisible = false;
    },
  },
  mounted() {},
  methods: {
    logout() {
      this.$auth.logout().then(() => location.reload());
    },
  },
};
</script>

<style lang="scss"></style>
